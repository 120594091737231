.accordion {
  margin-bottom: 10px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: var(--primary-color, #f4f4f4); */
  color: var(--text-color, #333);
  font-size: 16px;
  padding: 8px 0px;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
}

.accordionHeader:hover {
  /* background-color: #e0e0e0; */
}

.icon {
  font-size: 24px;
  transition: transform 0.3s ease;
}

.accordionContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
  color: #333;
}

.accordionContent.open {
  max-height: 150px;
  padding: 10px 0px;
}
