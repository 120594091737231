:root {
  --primary-color: #0096ff;
  --secondary-color: #13193b;
  --text-color: #000;
  --text-color-muted: rgba(28, 28, 28, 0.7);
  --card-shadow: rgba(0, 0, 0, 0.1);
  --card-hover-shadow: rgba(0, 0, 0, 0.15);
  --font-size-small: 0.9rem;
  --font-size-medium: 1.7rem;
  --font-size-large: 2.5rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  letter-spacing: -0.1px;
}

html {
  scroll-behavior: smooth;
}

body {
  color: var(--text-color);
  line-height: 1.6;
  background-color: #fff;
}

a {
  text-decoration: none;
}

.h2 {
  font-size: var(--font-size-large);
  font-weight: 700;
  line-height: 1.3;
}

.h3 {
  color: var(--primary-color);
  font-size: var(--font-size-small);
  letter-spacing: 1.5px;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 160px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0;
  z-index: 999;
}

.logo {
  font-size: 28px;
  font-weight: bold;
  color: var(--primary-color);
  height: 35px;
}

.header nav {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header nav a {
  margin: 0 15px;
  color: #000;
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.header nav a:hover {
  color: var(--primary-color);
}

.header .header-btns {
  display: flex;
  align-items: center;
}

.header .header-btns .signup {
  color: #fff;
  background-color: #007bff;
  margin-left: 20px;
  padding: 10px 25px;
  font-size: var(--font-size-small);
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .header-btns .signup:hover {
  background-color: var(--secondary-color);
  box-shadow: 0 8px 16px rgba(0, 123, 255, 0.2);
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 4px;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: #000;
  transition: all 0.3s ease;
}

.hamburger.open div:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open div:nth-child(2) {
  opacity: 0;
}

.hamburger.open div:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .header {
    padding: 20px;
  }

  nav {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  nav.show {
    display: flex;
  }

  .header-btns {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .logo {
    height: 40px;
  }

  .header .header-btns .signup {
    font-size: 0.8rem;
  }
}

.kotlin {
  height: 80px;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 100px 20px;
  color: #fff;
  min-height: 100vh;
  background-image: url("/src/img/hero-bg.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #10162548, #10162500);
  background-image: url("/src/img/hero-bg-1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.8;
  z-index: 1;
}

.hero-text {
  position: relative;
  z-index: 2;
  max-width: 800px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1.5s ease-out forwards;
}

.hero h1 {
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 20px;
  line-height: 1.2;
  line-height: 1.3;
  margin-bottom: 20px;
  animation: slideIn 1.2s ease forwards;
}

.hero p {
  font-size: 1.3rem;
  color: #e0e0e0;
  margin-bottom: 40px;
}

.buttons {
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
}

.hero-btn {
  padding: 12px 25px;
  font-size: var(--font-size-medium);
  font-weight: bold;
  font-size: 1.3rem;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #fff;
  color: var(--secondary-color);
  margin-bottom: 10px;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.hero-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 123, 0.2);
  background-color: var(--primary-color);
  color: #fff;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.hero-btn .icon {
  margin-left: 8px;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.features {
  padding: 80px 40px;
  background-color: #fafafa;
}

.features h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}

.features .subtitle {
  font-size: 1.1rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto 50px;
  line-height: 1.6;
}

.features-list {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.feature {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 250px;
  text-align: center;
}

.feature:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.feature-icon {
  font-size: 40px;
  color: var(--primary-color);
  margin-bottom: 15px;
}

.feature h3 {
  font-size: 1.1rem;
  color: #000;
  margin-bottom: 10px;
  font-weight: 600;
}

.feature p {
  font-size: 0.95rem;
  color: #000;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .header {
    padding: 20px 10px;
  }
  .header nav {
    display: none;
    flex-direction: column;
    position: absolute;
    right: 0px;
    left: 0px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 9;
  }

  .header nav.show {
    display: flex;
  }

  .header nav {
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease;
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  .header nav.show {
    opacity: 1;
    transform: translateY(0);
    max-height: 500px;
    visibility: visible;
  }

  .header nav a {
    font-size: 18px;
    font-weight: 500;
  }

  .hero,
  .form-section {
    background-size: 100% 100%;
    background-attachment: scroll;
  }

  @keyframes slideFadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideFadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-20px);
    }
  }

  .header nav.show {
    animation: slideFadeIn 0.4s ease forwards;
  }

  .header nav:not(.show) {
    animation: slideFadeOut 0.4s ease forwards;
  }

  .hamburger {
    display: flex;
  }

  .hero {
    flex-direction: column;
    padding: 40px 20px;
  }

  .hero-text h1 {
    font-size: 36px;
  }

  .hero-text p {
    font-size: 1.1rem;
  }

  .buttons {
    flex-direction: column;
  }

  .hero-btn,
  .watch-demo {
    width: 100%;
    margin: 10px 0;
  }

  .features-list {
    grid-template-columns: 1fr;
  }
}

.services-section {
  padding: 80px 10%;
  background-color: #fff;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.services-header {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 40px;
}

.header-left h3 {
  color: var(--primary-color);
  font-size: var(--font-size-small);
  letter-spacing: 1.5px;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.header-right {
  margin-top: 20px;
  color: var(--text-color);
  line-height: 1.6;
  font-size: 1.2rem;
}

.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  margin-top: 40px;
}

.service-card {
  position: relative;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px 5px var(--card-shadow);
  text-align: center;
}

.service-icon {
  font-size: 36px;
  color: var(--primary-color);
  margin-bottom: 15px;
  height: 40px;
}

.service-card h3 {
  font-size: 1.2rem;
  color: var(--text-color);
  margin-bottom: 10px;
}

.service-card p {
  font-size: 0.95rem;
  color: var(--text-color);
  line-height: 1.5;
}

.service-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--primary-color);
  color: #fff;
  font-size: 0.8rem;
  padding: 5px 10px;
  border-radius: 12px;
  font-weight: 600;
}

.text-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.text-columns h3 {
  grid-column: 1 / -1;
  color: var(--primary-color);
  font-size: var(--font-size-small);
  font-weight: bold;
  text-transform: uppercase;
}

.text-columns h2 {
  grid-column: 1 / -1;
  font-size: var(--font-size-large);
  font-weight: bold;
  color: var(--text-color);
}

.text-columns p {
  font-size: var(--font-size-medium);
  color: var(--text-color);
  grid-column: 1 / -1;
}

.service-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.service-card h4 {
  font-size: 1.25rem;
  color: var(--text-color);
  margin-bottom: 10px;
}

.service-card p {
  font-size: 0.9rem;
  color: var(--text-color-muted);
}

@media (max-width: 768px) {
  .services-list {
    grid-template-columns: 1fr;
  }

  .text-columns {
    grid-template-columns: 1fr;
  }

  .service-cards {
    grid-template-columns: 1fr;
  }

  .service-card {
    padding: 15px;
  }
}

.features-section {
  display: flex;
  justify-content: space-between;
  padding: 5rem 11rem;
  max-width: 100vw;
  margin: 0 auto;
  background-color: #e4f1ff;
}

.features-section p {
  max-width: 500px;
  padding: 1rem 0;
  font-size: 1.2rem;
}

.features-section .checklist {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.features-section .checklist li .icon {
  padding-right: 12px;
  font-size: 2.2rem;
  color: var(--primary-color);
}

.left-column {
  flex: 1;
  padding-right: 1rem;
}

.left-column h2 {
  margin-bottom: 1rem;
}

.checklist {
  list-style: none;
  padding: 0;
}

.checklist li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.check-icon {
  color: #007bff;
  margin-right: 0.5rem;
}

.right-column {
  flex: 1;
}

.card-grid {
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 1rem;
}

.features-card {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: 500px;
}

.features-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card {
  border-radius: 20px;
}

.card img {
  width: 100%;
  height: 30px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .features-section {
    flex-direction: column;
    padding: 2rem;
  }

  .card-grid {
    grid-template-columns: 1fr;
  }

  .features-card {
    height: 300px;
  }
}

.content h3 {
  color: #007bff;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.content p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

.hero-btn {
  padding: 10px 20px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
}

.hero-btn:hover {
  background-color: #355bb5;
}

.course-outline {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-color: #f7f9fc;
}

.outline-header {
  text-align: center;
  margin-bottom: 30px;
}

.outline-header h3 {
  font-size: var(--font-size-small);
  color: #007bff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.outline-header h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.outline-header p {
  color: #666;
  font-size: 1rem;
}

.outline-content {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
  max-width: 1200px;
}

@media (max-width: 768px) {
  .outline-content {
    flex-direction: column;
    align-items: center;
  }
}

.features {
  flex: 1;
  text-align: left;
}

.features p {
  font-size: 1rem;
  color: #000;
  margin-bottom: 10px;
}

.features .checkmark {
  color: #007bff;
  margin-right: 8px;
}

.modules {
  flex: 2;
  position: relative;
}

.module {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.circle {
  width: 35px;
  height: 35px;
  background-color: #e5edff;
  color: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  margin-right: 20px;
}

.module-content {
  background-color: #f1f6ff;
  padding: 15px 20px;
  border-radius: 8px;
}

.module-content h3 {
  margin: 0;
  color: #007bff;
}

.module-content p {
  color: #666;
}

@media (max-width: 768px) {
  .module {
    flex-direction: column;
    align-items: flex-start;
  }

  .circle {
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .h2 {
    font-size: var(--font-size-medium);
  }

  .outline-header p {
    font-size: 0.9rem;
  }

  .features p {
    font-size: 0.9rem;
  }

  .module-content {
    padding: 10px 15px;
  }

  .service-card .title {
    font-size: 1.2rem;
  }
}

.faq-section {
  text-align: center;
  padding: 50px 20px;
  background-color: #f1f6ff;
}

.faq-section h3 {
  font-size: 1rem;
  color: #355bb5;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-weight: 600;
}

.faq-section h2 {
  font-size: 2.2rem;
  margin-bottom: 25px;
  color: #333;
}

.faq-container {
  display: grid;
  gap: 15px;
  max-width: 750px;
  margin: 0 auto;
}

.faq-item {
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-size: 1.1rem;
  cursor: pointer;
  color: #333;
  font-weight: 600;
  border-bottom: 1px solid #e0e7ff;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease;
  font-size: 0.95rem;
  color: #555;
  padding: 0 20px;
  line-height: 1.6;
}

.faq-answer p {
  margin: 0;
  padding: 12px 0;
}

.faq-item.active .faq-answer {
  padding: 15px 20px;
  max-height: 600px;
}

.faq-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.about-section {
  text-align: center;
  padding: 60px 20px;
  background-color: #fff;
}

.about-label {
  font-size: 0.9rem;
  color: #4973f2;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  display: inline-block;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #e0e7ff;
  border-radius: 15px;
}

.about-content h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

.about-content p {
  font-size: 1.2rem;
  max-width: 900px;
  margin: 0 auto 40px;
}

.about-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.stat-card {
  background-color: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 22px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.stat-card h3 {
  font-size: 2rem;
  color: #4973f2;
  margin-bottom: 5px;
}

.stat-card h2 {
  font-size: 1.2rem;
}

.stat-card p {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 5px;
  font-weight: bold;
}

.stat-card span {
  font-size: 0.9rem;
  color: #666;
}

@media (max-width: 768px) {
  .about-stats {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .about-stats {
    grid-template-columns: 1fr;
  }

  .about-content h2 {
    font-size: 2rem;
  }

  .about-content p {
    font-size: 0.9rem;
  }

  .stat-card h3 {
    font-size: 1.5rem;
  }

  .stat-card p {
    font-size: 1rem;
  }

  .stat-card span {
    font-size: 0.8rem;
  }
}

.features {
  padding: 80px 10%;
  background-color: #fff;
}

.features h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}

.features .subtitle {
  font-size: 1.1rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto 50px;
  line-height: 1.6;
}

.features-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.feature {
  background-color: #fff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  font-size: 2rem;
  color: #007bff;
  margin-bottom: 15px;
}

.feature h3 {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
}

.feature p {
  font-size: 0.95rem;
  color: #666;
  line-height: 1.5;
}

.form-section {
  padding: 80px 5%;
  background-color: var(--secondary-color);
  background-image: url("/src/img/hero-bg-1.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #fff;
  text-align: center;
}

.form-container {
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  color: #000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}

.form-container h2 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  color: #000;
}

.section-header {
  font-size: 1.2rem;
  color: #000;
  margin-bottom: 0.5rem;
}

.form-label {
  display: block;
  margin-bottom: 1rem;
}

.input-field,
.select-field,
.textarea-field {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  margin-top: 0.25rem;
  border: 1px solid #ddd;
  border-radius: 25px;
  box-sizing: border-box;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 35px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
}

.submit-button:hover {
  background-color: var(--secondary-color);
}

.footer {
  background-color: var(--secondary-color);
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.footer p {
  margin: 0;
}

.footer-social {
  display: flex;
  gap: 15px;
}

.footer-social a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  transition: color 0.3s;
}

.footer-social a:hover {
  color: #4e89f9;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4e89f9;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s, background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-to-top:hover {
  background-color: #357ae8;
  opacity: 1;
}

/* Keyframes for Fade-In and Fade-Out */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Default State */
.signup {
  opacity: 0;
  visibility: hidden;
}

.signup.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards;
  visibility: visible;
}

.signup.fade-out {
  animation: fadeOut 0.5s ease-in-out forwards;
  visibility: hidden;
}

.pricing-section {
  background: linear-gradient(to bottom, #0b0c1a, #13193b);
  color: #ffffff;
  text-align: center;
  padding: 4rem 2rem;
}

.pricing-section h3 {
  text-transform: uppercase;
  font-size: var(--font-size-small);
}

.pricing-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-transform: capitalize;
}

.pricing-description {
  font-size: 1.2rem;
  margin-bottom: 3rem;
  color: #bdbddb;
}

.pricing-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.pricing-card {
  background: #ffffff;
  border-radius: 20px;
  padding: 2rem;
  width: 600px;
  height: 600px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: #000000;
}

.plan-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #333333;
}

.plan-price {
  font-size: 2rem;
  font-weight: bold;
  margin: 0.5rem 0;
  color: var(--text-color);
}
.plan-price2 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0.5rem 0;
  color: var(--primary-color);
}

.price {
  color: #000;
}

.plan-description {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #555555;
}

.plan-features {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
}
.plan-features div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.plan-features p {
  margin: 0;
  line-height: 1.5;
}

.plan-features li {
  margin: 0.5rem 0;
  color: #000000;
}

.get-started-btn {
  background: var(--primary-color);
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  width: 100%;
  transition: background 0.3s ease;
  margin-top: auto;
  background-color: #007bff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
}

.get-started-btn:hover {
  background: #000;
}
