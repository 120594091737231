.form-section {
  max-width: 900px;
  margin: 0 auto;
  padding: 50px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 90vh;
  position: relative;
}

/* Section headers */
.section-header {
  font-size: 1rem;
  margin: 20px 0;
  color: #333;
}

/* Two-column layout */
.two-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

/* Form labels */
.form-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-size: 14px;
}

/* Input and select fields */
.input-field,
.select-field {
  margin-top: 5px;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 14px;
}

.input-field:focus,
.select-field:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.input-field::placeholder,
.select-field::placeholder {
  font-size: 0.8rem;
  color: #aaa;
  opacity: 1;
}

.input-field::-webkit-input-placeholder,
.select-field::-webkit-input-placeholder {
  font-size: 0.8rem;
  color: #aaa;
}

.input-field:-ms-input-placeholder,
.select-field:-ms-input-placeholder {
  font-size: 0.8rem;
  color: #aaa;
}

.input-field::-ms-input-placeholder,
.select-field::-ms-input-placeholder {
  font-size: 0.8rem;
  color: #aaa;
}

.submit-button {
  padding: 15px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
}

.submit-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .two-column {
    grid-template-columns: 1fr;
  }
}

.form-section::-webkit-scrollbar {
  width: 10px;
}

.form-section::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 50px;
}

.form-section::-webkit-scrollbar-thumb {
  background: #c3c3c3;
  border-radius: 50px;
}

.form-section::-webkit-scrollbar-thumb:hover {
  background: #c3c3c3;
}

.form-section {
  scrollbar-width: thin;
  scrollbar-color: #c3c3c3 #f1f1f1;
}

@media (max-width: 768px) {
  .form-section {
    padding: 20px;
  }
  .two-column {
    grid-template-columns: 1fr;
    padding: 0 10px;
  }

  .form-label {
    width: 100%;
    padding: 0;
  }

  .input-field,
  .select-field {
    width: 100%;
    padding: 10px;
  }

  .submit-button {
    width: 100%;
    padding: 12px 10px;
  }
}
